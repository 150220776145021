import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import { ReducerStateContant } from "./app/helper/constants/general-const";
import { StateCompleted } from "./app/helper/functions/general-func";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Toast from "./app/components/shared/Toast";
import { webGetStaticConfigurationACTION } from "./app/redux-store/actions/webSettings.Actions";
import { WebAdminContext } from './web-admin-context';

const App = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [authorizedAccess, setAuthorizedAccess] = useState(ReducerStateContant.pending);
  const webSettingsReducer = useSelector((state) => state.webSettingsReducer);
  const [websiteSetttings, setWebsiteSetttings] = useState(null);

  useEffect(()=>{
    dispatch(webGetStaticConfigurationACTION());
  },[])
  useEffect(() => {
    if (isLoggedIn) {
      setAuthorizedAccess(ReducerStateContant.fulfilled)
    }
  }, [isLoggedIn]);
  useEffect(() => {

    if (StateCompleted(webSettingsReducer.webStaticConfiguration)) {
      if (webSettingsReducer.webStaticConfiguration.data && webSettingsReducer.webStaticConfiguration.data[window.location.hostname]) {
        setWebsiteSetttings(webSettingsReducer.webStaticConfiguration.data[window.location.hostname]);
      }
      else {
        setWebsiteSetttings(webSettingsReducer.webStaticConfiguration.data.default);
      }
    }
  }, [webSettingsReducer.webStaticConfiguration]);
  return (
    websiteSetttings ? <>
      <WebAdminContext.Provider value={websiteSetttings}>
        {
          isLoggedIn ? <AppRoutes authorizedAccess={authorizedAccess} active={isLoggedIn} /> : <AuthRoutes />
        }
        <ToastContainer />
        </WebAdminContext.Provider>
      </> : <><h1>Please wait</h1></>

      );
}

      export default App;
