import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { loginACTION, logoutACTION } from '../actions/authAction';
const usersInfo = JSON.parse(localStorage.getItem("usersInfo"));


const INITIAL_STATE = usersInfo
    ? {
        isLoggedIn: true,
        usersInfo: usersInfo,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    }

    :

    {
        isLoggedIn: false,
        usersInfo: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    };


const authReducer = createSlice({
    name: 'Authentication',
    initialState: INITIAL_STATE,
    reducers: {

    },
    extraReducers: (builder) => {
        //------------------------------- loginACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(loginACTION.fulfilled, (state, action) => {
            //console.log('loginACTION',action)
            state.isLoggedIn = true
            state.usersInfo = action.payload.data;
            state.apiMsg.status = action.meta.requestStatus;
            state.apiMsg.message = action.payload.message.message

        }),
            builder.addCase(loginACTION.pending, (state, action) => {
                //console.log('loginACTION pending',action)
                state.apiMsg.status = action.meta.requestStatus;

            }),
            builder.addCase(loginACTION.rejected, (state, action) => {
                // console.log('loginACTION rejected',action)
                state.isLoggedIn = false;
                state.usersInfo = null;
                state.apiMsg.message = action.error.message
                state.apiMsg.status = action.meta.requestStatus;
                toast.error(action.error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "login-error"
                });
            }),

            //------------------------------- loginACTION end -------------------------

            //------------------------------- logoutACTION start -------------------------
            builder.addCase(logoutACTION.fulfilled, (state, action) => {
                // console.log('logoutACTION',action)
                state.isLoggedIn = false
                state.usersInfo = null;
                state.apiMsg.status = null;
                state.apiMsg.message = null;

            })
        //------------------------------- logoutACTION end -------------------------

    }
})

export const { } = authReducer.actions;
export default authReducer.reducer;