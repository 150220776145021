import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
    webGetStaticConfigurationACTION
} from '../actions/webSettings.Actions'
import _ from "lodash";

const INITIAL_STATE = {
    webStaticConfiguration: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const webSettingsReducer = createSlice({
    name: "WebSetting",
    initialState: INITIAL_STATE,
    reducers: {
        RESETWEBSETTING(state, action) {
            state.webSettings = INITIAL_STATE.webSettings
            state.insertUpdatewebSettings = INITIAL_STATE.insertUpdatewebSettings
        }
    },
    extraReducers: (builder) => {

        builder.addCase(webGetStaticConfigurationACTION.fulfilled, (state, action) => {
            // console.log("webGetStaticConfigurationACTION",action)
            state.webStaticConfiguration.data = action.payload.data;
            state.webStaticConfiguration.apiMsg.status = "fulfilled";
            state.webStaticConfiguration.apiMsg.message = "ok";
           
        });
        builder.addCase(webGetStaticConfigurationACTION.pending, (state, action) => {
            state.webStaticConfiguration.apiMsg.status = "pending";
            state.webStaticConfiguration.apiMsg.message = "";
           
        });
        builder.addCase(webGetStaticConfigurationACTION.rejected, (state, action) => {
            state.webStaticConfiguration.apiMsg.status = "rejected";
            state.webStaticConfiguration.apiMsg.message = "";
          
        });

    }
});

export const { RESETWEBSETTING } = webSettingsReducer.actions;
export default webSettingsReducer.reducer;
