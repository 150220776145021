import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { getallRoleACTION, getRoleACTION, insertUpdateRoleAction, roleChangeStatusACTION, roleDeleteACTION } from "../../actions/Roles/roleAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    roles: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedRole: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

};
const roleReducer = createSlice({
    name: "role",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetRoleDetailsState(state, action) {
            state.selectedRole = INITIAL_STATE.selectedRole;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
        },
    },
    extraReducers: (builder) => {

        // getallRoleACTION start

        builder.addCase(getallRoleACTION.fulfilled, (state, action) => {
            state.roles.data = action.payload.data;
            state.roles.apiMsg.status = action.meta.requestStatus;
            state.roles.apiMsg.message = "success";
        });
        builder.addCase(getallRoleACTION.pending, (state, action) => {
            state.roles.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getallRoleACTION.rejected, (state, action) => {
            state.roles.apiMsg.message = action.error.message;
            state.roles.apiMsg.status = action.meta.requestStatus;
        });


        // getallRoleACTION end
        //------------------------------- getRoleACTION start -------------------------

        builder.addCase(getRoleACTION.fulfilled, (state, action) => {
            state.selectedRole.data = action.payload.data;
            state.selectedRole.apiMsg.status = action.meta.requestStatus;
            state.selectedRole.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getRoleACTION.pending, (state, action) => {
            state.selectedRole.data = null;
            state.selectedRole.apiMsg.message = action.meta.requestStatus;;
            state.selectedRole.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getRoleACTION.rejected, (state, action) => {
            state.selectedRole.apiMsg.message = action.error.message;
            state.selectedRole.apiMsg.status = action.meta.requestStatus;
        });


        //   getRoleACTION end

        // insertUpdateRoleAction start

        builder.addCase(
            insertUpdateRoleAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.roles.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.roles.data.records = updatedRecord;
                }
                else {
                    if (state.roles.data.records.length) {
                        state.roles.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.roles.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateRoleAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateRoleAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateRoleAction end

        // roleChangeStatusACTION start


        builder.addCase(roleChangeStatusACTION.fulfilled, (state, action) => {
            state.roles.data.records = state.roles.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(roleChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(roleChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // roleChangeStatusACTION end



        //------------------------------- roleDeleteACTION start -------------------------

        builder.addCase(roleDeleteACTION.fulfilled, (state, action) => {

            state.roles.data.records = state.roles.data.records.filter((item, index) => item.id !== action.payload.data.id)

            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(roleDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(roleDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- roleDeleteACTION end -------------------------
    },
});
export const { RESET, ResetRoleDetailsState } = roleReducer.actions;
export default roleReducer.reducer;