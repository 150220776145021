
const ReducerStateContant = {
    fulfilled: 'fulfilled',
    pending: 'pending',
    rejected: 'rejected'
}
export const ValidationExpression = {
    Domain: /^(?![-._])[a-z0-9_-]+(?<![-._])$/,
    Email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
    Password:/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])[A-Za-z\d@$!%*#?&]{10,}$/
}


const defaultButtonColor = '#337ab7'

export { ReducerStateContant, defaultButtonColor };