import secureLocalStorage from "react-secure-storage";

export const setLocalStore = (KEY, VAlUE) => {

    secureLocalStorage.setItem(KEY, VAlUE);

}
export const getLocalStore = (KEY) => {

    return secureLocalStorage.getItem(KEY);
}

export const clearLocalStore = (allclear, key) => {

    if (allclear) {
        // To clear the whole data stored in localStorage
        secureLocalStorage.clear();
    } else {

        // To clear a specific item
        secureLocalStorage.removeItem(key);
    }


}