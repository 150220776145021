import { createAsyncThunk } from "@reduxjs/toolkit";

export const webGetStaticConfigurationACTION = createAsyncThunk(
  "WebSetting/getStaticConfiguration",
  async () => {
    return fetch(`${process.env.PUBLIC_URL}/localization.json`)
      .then(response => {
        return response.json();
      }).then(data => {

        return  {data:data};
      }).catch((e) => {
        console.log(e);
      });
  }

);

